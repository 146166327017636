/*! soopr.co soopr.js - (C) 2020-2021 Abhinav Saxena - https://www.soopr.co/license */
import ClipboardJS from 'clipboard';
import './style.css';

(function () {
  'use strict';

  let debug = __CONFIG__.debug || false;

  // constants
  const SOOPR_USER_COOKIE = "_soopr_ch"
  const SOOPRBTN = "soopr-btn-"
  const SOOPR_API_BASE_URL = __CONFIG__.soopr.api
  const SOOPR_HANDLE = "SooprCo"
  const TWITTER = 'twitter'
  const FACEBOOK = 'facebook'
  const LINKEDIN = 'linkedin'
  const WHATSAPP = 'whatsapp'
  const COPY = 'copy'
  const LIKE = 'like'
  const SVG_PATH = 'p'
  const SVG_STROKE_WIDTH = 'sw'
  const CLICK_TO_LIKE_PAGE = "Click to like the page"
  const CLICK_TO_UNLIKE_PAGE = "Click to unlike the page"
  const CLICK_TO_COPY_LINK = "Click to copy the link"
  const LINK_COPIED = "Link copied!"

  let CH = getCookie(SOOPR_USER_COOKIE)
  console_log(CH)

  const ICONS = {
    twitter: {
      p: "M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"
    },
    facebook: {
      p: "M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z"
    },
    copy: {
      p: "M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z",
      sw: 2
    },
    like: {
      p: "M17.4526 12.0003C10 19.4532 10 19.4532 2.54737 12.0003C0.48421 9.93709 0.48421 6.61066 2.54737 4.54742C4.61053 2.48419 7.93684 2.48419 10 4.54742C12.0632 2.48419 15.3895 2.48419 17.4526 4.54742C19.5158 6.61066 19.5158 9.93709 17.4526 12.0003Z"
    },
    likecount: {
      p: ""
    },
    whatsapp: {
      p: "M18 8.775C18 13.6125 14.0475 17.535 9.165 17.535C7.6125 17.535 6.165 17.1375 4.89 16.4475L0 18L1.5975 13.2975C0.763343 11.9338 0.322116 10.3661 0.3225 8.7675C0.33 3.9225 4.29 0 9.1725 0C14.04 0 18 3.9225 18 8.775ZM9.165 1.3875C5.07 1.3875 1.74 4.695 1.74 8.76C1.74 10.3725 2.265 11.865 3.15 13.08L2.22 15.825L5.07 14.925C6.28653 15.7224 7.71046 16.1448 9.165 16.14C13.26 16.14 16.59 12.84 16.59 8.7675C16.5801 6.80615 15.7929 4.92874 14.4011 3.54677C13.0093 2.16479 11.1264 1.391 9.165 1.395V1.3875ZM13.6275 10.785C13.5675 10.695 13.425 10.6425 13.2075 10.5375C12.9975 10.4325 11.9325 9.9075 11.73 9.84C11.535 9.765 11.385 9.7275 11.2425 9.945C11.0925 10.1625 10.68 10.6425 10.56 10.785C10.4325 10.935 10.305 10.95 10.0875 10.845C9.87 10.7325 9.1725 10.5075 8.3475 9.7725C7.87637 9.33947 7.47198 8.83904 7.1475 8.2875C7.0125 8.07 7.125 7.9575 7.2375 7.8525C7.335 7.755 7.455 7.5975 7.56 7.4775C7.6725 7.35 7.71 7.2525 7.7775 7.1175C7.8525 6.9675 7.815 6.8475 7.7625 6.7425C7.7025 6.63 7.275 5.5725 7.0875 5.145C6.9075 4.71 6.7275 4.785 6.6075 4.785C6.48 4.785 6.33 4.7625 6.1875 4.7625C6.0375 4.7625 5.8125 4.8225 5.61 5.0325C5.415 5.25 4.86 5.7675 4.86 6.8325C4.86 7.8825 5.6325 8.9025 5.7375 9.0525C5.8425 9.195 7.2375 11.43 9.435 12.2925C11.64 13.155 11.64 12.87 12.0375 12.8325C12.435 12.795 13.3125 12.3075 13.5 11.8125C13.68 11.31 13.68 10.875 13.6275 10.785"
    },
    linkedin: {
      p: "M7.42857 6.42857H10.9866V8.3539H11.0371C11.5317 7.41657 12.7437 6.42857 14.5497 6.42857C18.3057 6.42857 19 8.89676 19 12.106V18.6429H15.2911V12.8479C15.2911 11.4654 15.2646 9.68752 13.3617 9.68752C11.4306 9.68752 11.1357 11.193 11.1357 12.7474V18.6429H7.42857V6.42857ZM1 6.42857H4.85714V18.6429H1V6.42857ZM4.85714 3.03571C4.85714 3.57562 4.65395 4.09341 4.29228 4.47518C3.9306 4.85695 3.44006 5.07143 2.92857 5.07143C2.41708 5.07143 1.92654 4.85695 1.56487 4.47518C1.20319 4.09341 1 3.57562 1 3.03571C1 2.49581 1.20319 1.97802 1.56487 1.59625C1.92654 1.21448 2.41708 1 2.92857 1C3.44006 1 3.9306 1.21448 4.29228 1.59625C4.65395 1.97802 4.85714 2.49581 4.85714 3.03571V3.03571Z"
    },
  }

  const ICON_TYPES = {
    base: 'd',
    circle: 'c',
    square: 'sq'
  }

  const ICON_SIZES = {
    small: 'sm',
    normal: 'def',
    large: 'lg',
    extra_large: 'xl'
  }

  const DEFAULT_NETWORKS = [TWITTER, FACEBOOK, LINKEDIN, COPY]

  const SERVICES = {
    twitter: "https://twitter.com/intent/tweet?text={text}&url={url}",
    facebook: "http://www.facebook.com/sharer.php?t={text}&u={url}",
    whatsapp: "https://api.whatsapp.com/send?text={text} %20 {url}",
    linkedin: "https://www.linkedin.com/sharing/share-offsite/?url={url}"
  }

  const DEFAULT_ICON_SIZE = ICON_SIZES['normal']
  const DEFAULT_ICON_TYPE = ICON_TYPES['base']
  const BASE_ICON_TYPE = ICON_TYPES['base']



  function console_log(msg) {
    if (debug) {
      console.log(msg);
    }
  }

  console_log(__CONFIG__);
  var module = {}

  var location = window.location
  var document = window.document

  const HOST = location && location.host


  let scriptEl = document.querySelector("[data-soopr-token^='pt_']")
  const authToken = scriptEl && scriptEl.getAttribute('data-soopr-token')

  function queryclass(name) {
    if (document.querySelectorAll) {
      return document.querySelectorAll('.' + name);
    }
    if (document.getElementsByClassName) {
      return document.getElementsByClassName(name);
    }
    let elems = document.getElementsByTagName('div');
    let elemsArray = [];
    for (var i = 0; i < elems.length; i++) {
      if (elems[i].className.split(' ').indexOf(name)) {
        elemsArray.push(els[i]);
      }
    }
    return elemsArray;
  }

  async function createSooprPayload(authToken, options = {}) {
    let payload = {}
    let props = {}
    payload.u = document.URL
    payload.t = document.title
    payload.w = window.innerWidth
    payload.p = location.pathname
    payload.h = HOST
    payload.r = document.referrer || null
    
    props.hh = location.hash
    props.pt = location.port
    props.pc = location.protocol

    if (options) {
      if (options.meta) {
        payload.m = JSON.stringify(options.meta)
      }
      if (options.pg) {
        payload.pg = options.pg
      }
    }
    if (props) {
      payload.pr = props
    }

    const authHeader = 'Bearer ' + authToken
    const ch = 'ch ' + CH

    const opts = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader,
        'X-CH': ch
      })
    }

    return opts
  }

  async function sooprCall(soopr_api_url, authToken, options = {}) {
    let opts = await createSooprPayload(authToken, options)
    let response = await fetch(soopr_api_url, opts)
    if (response.ok) {
      let outJson = await response.json();
      console_log(outJson);
      return outJson;
    } else {
      console_log(response)
      if (debug) {
        alert("HTTP-Error: " + response.status)
      }
    }
  }

  function getDefaultSooprConfigOptions() {
    let config = {}
    config.iconSize = DEFAULT_ICON_SIZE
    config.iconType = DEFAULT_ICON_TYPE
    config.networks = DEFAULT_NETWORKS
    config.lc = 0
    config.lf = false
    config.short = false
    config.url = document.URL
    config.tt = true
    config.dbg = false
    return config
  }

  async function getSooprConfigOptions(authToken, options = {}) {
    let confJson = await sooprCall(SOOPR_API_BASE_URL + "/conf", authToken, options)
    console_log(confJson);
    let config = {}
    config.iconSize = confJson.icon_size
    config.iconType = confJson.icon_type
    config.domain = confJson.domain
    config.networks = confJson.networks
    config.lc = confJson.lc
    config.lf = confJson.lf
    config.settings = confJson.settings 
    config.cookie_enabled = confJson.ce
    const keys = Object.keys(confJson.settings) || []
    keys.forEach((key, index) => {
      config[key] = confJson.settings[key]
    })
    config.url = config.short ?  confJson.ps : confJson.pu
    config.ch = checkCookie(SOOPR_USER_COOKIE, confJson.ch, confJson.ce)

    /*
    if(config.dbg) {
      debug = true
    }
    */
    return config
  }

  async function toggleLike(page_url, authToken, options = {}) {
    options['pg'] = page_url
    let out = await sooprCall(SOOPR_API_BASE_URL + "/toggle_like", authToken, options);
    return out
  }

  function setCookie(cname, cvalue, exdays, domain=null) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    var dom = "";
    if(domain) {
      var dom = "domain="+domain;
    }
    window.document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + dom ;
  }
  
  function getCookie(cname) {
    var name = cname + "=";
    var ca = window.document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function deleteCookie(cname) {
    setCookie(cname, "", -1)
  }
  
  function checkCookie(cname, cvalue, cookie_enabled) {
    if(!cookie_enabled) {
      console_log("-------------")
      console_log("deleting cookie " + cvalue)
      console_log("-------------")
      deleteCookie(cname)
      return cvalue
    }

    var value = getCookie(cname);
    if (value != "") {
      setCookie(cname, value, 365);
      console_log("resetting cookie " + value)
      return value
    } else {
      if (cvalue != "" && cvalue != null) {
        console_log("setting cookie " + cvalue)
        setCookie(cname, cvalue, 365);
        return cvalue;
      }
    }
  }

  /*
  *  Steps
   * 1. get domain and publish token
   * 2. check if soopr-btn exist
   * 3. get conf
   * 4. verify conf data with domain
   * 5. set up soopr options accordingly
   * 6. initialize soopr
   */

  function main() {

    if (window.phantom || window._phantom || window.__nightmare || window.navigator.webdriver) return;

    // 1. get domain and publish token
    if (!authToken) {
      console_log("SOOPR ERROR - soopr token not found")
      //return;
    }

    console_log(window);
    let els = queryclass('soopr-btn')

    // 2. check if soopr-btn exist
    if (els.length == 0) {
      console_log("SOOPR ERROR - no soopr-btn found found")
      return;
    }

    if (els.length > 0) {

      // 3. get conf

      if (authToken) {
        getSooprConfigOptions(authToken).then((sooprConfigOptions) => {
          console_log("soopr" + sooprConfigOptions)


          // 4. verify conf data with domain
          if (__CONFIG__.domainCheckEnabled) {
            if (sooprConfigOptions.domain != HOST && HOST != __CONFIG__.soopr.app) {
              console_log("SOOPR ERROR - trying to load soopr on unverified domain")
              return;
            }
          }
          
          CH = sooprConfigOptions.ch

          // 5. make options hash

          console_log(sooprConfigOptions)
          for (let i = 0; i < els.length; i++) {
            soopr(els[i], sooprConfigOptions);
          }
        });
      }
      else {
        let sooprConfigOptions = getDefaultSooprConfigOptions();
        console_log(sooprConfigOptions)
        for (let i = 0; i < els.length; i++) {
          soopr(els[i], sooprConfigOptions);
        }
      }
    }
  }

  function transition(el) {
    if (el) {
      let els = queryclass('soopr-circle')
      for (let i = 0; i < els.length; i++) {
        console_log("transasition")
        els[i].classList.add('transition');
        window.setTimeout(() => {
          els[i].classList.remove('transition');
        }, 10000);
      }
    }
  }

  function getIcon(name, iconType) {
    let svg = ICONS[name]
    let v = 30
    let tx = 5
    let ty = 5
    if ([TWITTER, WHATSAPP].includes(name)) {
      tx = 6
      ty = 6
    }
    let c = `<circle cx=${v / 2} cy=${v / 2} r=${v / 2} />`
    let klass = "soopr-circle"
    if (iconType == BASE_ICON_TYPE) {
      v = 20
      tx = 0
      ty = 2
      c = ``
      klass = "soopr-regular"
    }
    //let tx = svg['tx'] || 0
    //let ty = svg['ty'] || 0
    let d = svg[SVG_PATH]
    let sw = svg[SVG_STROKE_WIDTH]
    let stroke = sw ? `stroke-width=${sw}` : ''
    //let text = txt ? `<text x=${v/2} y=${v/2} dominant-baseline="central" text-anchor="middle">${txt}</text>` : ''

    let out = `<svg class="${klass}"xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 ${v} ${v}">
    ${c}
    <path class="path" ${stroke} transform="translate(${tx}, ${ty}) scale(1)" d="${d}" /></svg>`
    return out
  }

  function soopr(el, options) {
    options = options || {};
    if (el.getAttribute('data-widget-rendered')) return;
    el.setAttribute('data-widget-rendered', 'true');
    let prefix = el.getAttribute('data-prefix') || options.prefix || 'icon-';
    let text = el.getAttribute('data-text') || options.text || document.title;
    let url = el.getAttribute('data-url') || options.url || document.URL;
    let image = el.getAttribute('data-image') || options.image;

    let iconSize = el.getAttribute('data-icon-size') || options.iconSize || DEFAULT_ICON_SIZE;
    let iconType = el.getAttribute('data-icon-type') || options.iconType || DEFAULT_ICON_TYPE;

    let author = {
      twitter: el.getAttribute('data-twitter') || options.twitter || SOOPR_HANDLE,
      facebook: el.getAttribute('data-facebook') || options.facebook || SOOPR_HANDLE
    };

    // if sm or lg class is already specified, honour that
    let sooprSizeClassPresent = false
    for (let i = 0; i < el.classList.length; i++) {
      if (el.classList[i].match(SOOPRBTN)) {
        sooprSizeClassPresent = true
        console_log(el.classList[i]);
      }
    }
    if (!sooprSizeClassPresent) {
      el.classList.add(SOOPRBTN + iconSize);
    }

    function getIconClassName(name) {
      let className = SOOPRBTN + 'icon ' + SOOPRBTN + 'icon-' + name
      return className;
    }

    function getItemClassName(name, iconType) {
      let className = SOOPRBTN + 'item ' + SOOPRBTN + name
      let suffix = 'circle'
      if (iconType == BASE_ICON_TYPE) {
        suffix = 'reg'
      }
      return className + ' ' + SOOPRBTN + 'item-' + suffix
    }

    function triggerLikeTransition(el, name, lf) {
      if (lf) {
        el.classList.remove(SOOPRBTN + 'icon-' + name + '-empty')
        el.classList.add(SOOPRBTN + 'icon-' + name + '-full')
      } else {
        el.classList.remove(SOOPRBTN + 'icon-' + name + '-full')
        el.classList.add(SOOPRBTN + 'icon-' + name + '-empty')
      }
    }

    function addHelperText(el, text) {
      if (el) {
        el.setAttribute('aria-label', text);

        // TODO using options even if it is not passed
        if(options.tt) {  
          el.setAttribute('data-title', text)
        el.classList.add('stt')
        } else {
          el.setAttribute('title', text)
        }
      }
    }

    function createCopyElement(iconType) {
      const name = 'copy';
      let div = document.createElement('div');

      div.className = getItemClassName(name, iconType);

      let icon = document.createElement('a');
      icon.className = getIconClassName(name, iconType)
      addHelperText(icon, CLICK_TO_COPY_LINK)
      icon.setAttribute('data-clipboard-text', url);
      icon.innerHTML = getIcon(name, iconType);

      let clipboard = new ClipboardJS(icon);
      clipboard.on('success', function (e) {
        //addHelperText(icon, LINK_COPIED + e.text);
        addHelperText(icon, LINK_COPIED);
        e.clearSelection();
        setTimeout(removeHelperText, 10000);
      });

      function removeHelperText() {
        addHelperText(icon, CLICK_TO_COPY_LINK);
      }

      div.appendChild(icon);
      el.appendChild(div);
      return icon;
    }


    function createLikeCountElement(lc, lf, iconType) {
      const name = 'likecount'
      let div = document.createElement('div')
      div.className = getItemClassName(name, iconType)
      let icon = document.createElement('div')
      icon.className = getIconClassName(name, iconType)
      if (lc) {
        icon.innerText = lc;
      }
      triggerLikeTransition(icon, name, lf)

      div.appendChild(icon)
      el.appendChild(div)
      return icon;
    }

    function createLikeBtnElement(lf, iconType) {
      const name = 'like';
      let div = document.createElement('div');
      div.className = getItemClassName(name, iconType);

      let icon = document.createElement('a');
      icon.className = getIconClassName(name, iconType)

      if (lf) {
        addHelperText(icon, CLICK_TO_UNLIKE_PAGE)
      } else {
        addHelperText(icon, CLICK_TO_LIKE_PAGE)
      }
      icon.innerHTML = getIcon(name, iconType);
      triggerLikeTransition(icon, name, lf)

      div.appendChild(icon);
      el.appendChild(div);
      return icon;
    }

    function createLikeElement(lc, lf, iconType) {
      let likeBtnEl = createLikeBtnElement(lf, iconType)
      let likeCountEl = createLikeCountElement(lc, lf, iconType)

      likeBtnEl.addEventListener("click", function () {
        toggleLike(url, authToken).then((likeJson) => {
          likeCountEl.innerText = likeJson.lc
          if (likeJson.liked) {
            triggerLikeTransition(likeBtnEl, "like", true)
            triggerLikeTransition(likeCountEl, "likecount", true)
            addHelperText(likeBtnEl, CLICK_TO_UNLIKE_PAGE)
          } else {
            triggerLikeTransition(likeBtnEl, "like", false)
            triggerLikeTransition(likeCountEl, "likecount", false)
            addHelperText(likeBtnEl, CLICK_TO_LIKE_PAGE)
          }
        })
      })
    }

    function createShareElement(name, iconType) {
      //if (!author[name]) return;

      let div = document.createElement('div');
      div.className = getItemClassName(name, iconType);

      let icon = document.createElement('a');
      icon.className = getIconClassName(name, iconType)
      addHelperText(icon, `Share on ${name.replace(/^\w/, (c) => c.toUpperCase())}`)
      icon.setAttribute('rel', 'noopener noreferrer');
      icon.target = '_blank';

      let link = SERVICES[name];
      let data = text;

      if (author[name]) {
        if (name === 'twitter') {
          link += '&via=' + encodeURIComponent(author[name]);
        } else {
          data = text + ' via @' + author[name];
        }
      }

      link = link.replace('{text}', encodeURIComponent(data));
      link = link.replace('{url}', encodeURIComponent(url));

      icon.href = link;
      icon.innerHTML = getIcon(name, iconType);

      icon.onclick = function (e) {
        e.preventDefault && e.preventDefault();
        window.open(link, '_blank', 'width=600,height=500');
      };

      div.appendChild(icon);
      el.appendChild(div);
      return icon;
    }

    for (let i = 0; i < options.networks.length; i++) {
      console_log(options.networks[i])
      if (options.networks[i] == 'copy') {
        createCopyElement(options.iconType);
      } else if (options.networks[i] == 'like') {
        console_log("------")
        console_log(options.lc)
        console_log(options.lf)
        console_log("------")
        createLikeElement(options.lc, options.lf, options.iconType);
      } else {
        createShareElement(options.networks[i], options.iconType);
      }
    }

  }

  module.exports = soopr;

  main();

})();
